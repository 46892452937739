<template>
  <div class="bigbox">
    <div>
      <van-nav-bar title="开具发票" left-arrow @click-left="$router.back()"></van-nav-bar>
    </div>
    <div class="mainbox">
      <div class="title">发票详情</div>
      <div class="main">
        <van-cell-group inset>
          <van-field name="radio" label="抬头类型">
            <template #input>
              <van-radio-group   v-model="radio" direction="horizontal">
                <van-radio name="1">企业单位</van-radio>
                <van-radio name="2">个人/非企业</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field v-model="billRise" name="公司名称" label="公司名称" placeholder="请输入公司名称" />
          <van-field
          v-if="this.radio==1"
            v-model="dutyParagraph"
            type="dutyParagraph"
            name="公司税号"
            label="公司税号"
            placeholder="请输入公司税号"
          />
          <van-field
            v-model="remarks"
            type="remarks"
            name="更多内容"
            label="更多内容"
            placeholder="填写备注、地址等（非必填）"
          />
        </van-cell-group>
      </div>
      <div class="flex">
        <div>总金额</div>
        <div style="padding-right:50%">
          <span style="color:red;">{{this.price}}</span> 元
        </div>
        <!-- <div @click="go">
          共1张，查看详情
          <van-icon name="arrow" />
          
        </div> -->
      </div>
      <div class="title">接收方式</div>
      <div class="main">
        <van-cell-group inset>
          <van-field v-model="username" name="收件人" label="收件人" placeholder="请输入收件人" />
          <van-field
            v-model="phone"
            type="paragraph"
            name="手机号码"
            label="手机号码"
            placeholder="请输入手机号码"
          />
          <van-field v-model="email" type="email" name="邮箱地址" label="邮箱地址" placeholder="邮箱地址" />
        </van-cell-group>
      </div>
    </div>
    <button class="btn" @click="btn">提交</button>
  </div>
</template>

<script>
import { addbill_api } from '../http/api/service'
export default {
  name: 'TaxiH5webDraw',

  data() {
    return {
      phone: '',
      username: '',
      email: '',
      dutyParagraph: '',
      billRise: '',
      checkbox: false,
     radio: '1',
      remarks: '',
      price:'',
      orderIds:null
    }
  },

  mounted() {
     this.orderIds=JSON.parse( sessionStorage.getItem('idarr'))
     console.log(this.orderIds);
    this.price=sessionStorage.getItem('price')
    console.log(this.price);
  },

  methods: {
    to(val) {
      console.log(val)
    },
    async getbill() {
    
      let res = await addbill_api({
        userName: this.username,
        billType: this.radio, //发票类别 1:企业单位 2:个人/非企业
        billRise:this.billRise,
        dutyParagraph: this.dutyParagraph,
        billContent: '客运服务费',
        billPrice: '10',
        remarks: this.remarks,
        phone:this.phone, //手机号码
        email: this.email,
        orderIds: this.orderIds, //主键id (id以逗号隔开)
      })
      console.log(res)
      if(res.code==200){
        localStorage.setItem('email', this.email)
        this.$router.push({
        path:'/Okbuill'
      })
      }
    },
    btn(){
      this.getbill()
     
    },
   go(){
       this.$router.push({
        path:'/details'
      })
   }
  },
}
</script>

<style lang="scss" scoped>
.bigbox {
  background: #eee;
  height: 100vh;
  width: 100%;
  .mainbox {
    color: #333;
    .title {
      margin-left: 10px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
    }
    .main {
      background: #fff;
    }
    .flex {
      margin-top: 20px;
      display: flex;
      background: #fff;
      height: 50px;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
    }
  }
  .btn {
    background: #eb4541;
    height: 40px;
    width: 90%;
    margin: 0 5%;
    border: 0;
    color: #fff;
    margin-top: 30px;
    border-radius: 15px;
  }
}
</style>